import Cookies from 'js-cookie'
import {
  retrieveFromStorage,
  removeFromStorage,
  addToStorage
} from '../StorageService'

import { apiPlataformaIndicadores } from '@/services/api'

import {
  KEY_STORAGE_FIRST_NAME,
  KEY_CACHE_PROFILE_USER,
  KEY_STORAGE_USER,
  KEY_CACHE_TOKEN
} from '@/constants/user-profiles-enums'
import { TypeApiUserAcesso } from '@/@types/autenticacao'
import { useRouter } from 'next/navigation'

const logoutUser = () => {
  removeFromStorage(KEY_STORAGE_USER)
  removeFromStorage(KEY_STORAGE_FIRST_NAME)
  Cookies.remove(KEY_CACHE_PROFILE_USER)
  Cookies.remove(KEY_CACHE_TOKEN)
}

export type TypeUserAcesso = {
  codAdministradorGeral: number
  nomeCompleto: string
  emailInstitucional: string
  userId: string
  flagRegistroCompleto: boolean
  name: string
  cpfCnpj: string | ''
  usuario: {
    codUsuario: number
    cpfCnpj: string
    email: string
    flagAtivo: boolean
    nome: string
    dataCadastro: Date
    perfilList: string[]
  }
}

const getInfoUser = (): TypeApiUserAcesso | null => {
  const responseStorage = retrieveFromStorage(KEY_STORAGE_USER)
  if (responseStorage === null) return null

  try {
    return JSON.parse(responseStorage)
  } catch (error) {
    console.error('Failed to parse user info from storage:', error)
    return null
  }
}

const isUserLoggedIn = () => {
  try {
    const tokenCache = Cookies.get(KEY_CACHE_TOKEN)
    return tokenCache !== undefined && tokenCache !== null && tokenCache !== ''
  } catch (error) {
    console.error('Failed to retrieve login status:', error)
    return false
  }
}

async function findUserAcesso(cpf: string): Promise<TypeUserAcesso> {
  try {
    const response = await apiPlataformaIndicadores.get(
      `/acesso/buscar-usuario/${cpf}`
    )

    return response.data
  } catch (error: any) {
    console.error(
      'Erro ao buscar usuário no Acesso Cidadão:',
      error?.response?.data || error.message
    )
    throw error
  }
}

async function confirmProfileUser(
  codAdministradorGeral: number,
  cpfCnpj: string,
  nomeCompleto: string,
  emailInstitucional: string
): Promise<TypeUserAcesso> {
  try {
    const response = await apiPlataformaIndicadores.patch(
      `acesso/confirmacao-de-perfil/administrador-geral`,
      {
        codAdministradorGeral: codAdministradorGeral,
        cpfCnpj: cpfCnpj || '',
        nomeCompleto: nomeCompleto || '',
        emailInstitucional: emailInstitucional || '',
        flagRegistroCompleto: true
      }
    )

    addToStorage(KEY_STORAGE_USER, JSON.stringify(response.data))
    return response.data
  } catch (error: any) {
    console.error(
      'Erro ao confirmar perfil de usuário:',
      error?.response?.data || error.message
    )
    throw error
  }
}

async function isCadastroAtivo(cpfCnpj: string): Promise<boolean> {
  try {
    const response = await apiPlataformaIndicadores.get<boolean>(
      `acesso/status-usuario?cpf=${cpfCnpj}`
    )

    return response.data
  } catch (error) {
    console.error('Não foi possível verificar cadastro do usuário.', error)
    throw error
  }
}

export {
  logoutUser,
  isUserLoggedIn,
  getInfoUser,
  findUserAcesso,
  confirmProfileUser,
  isCadastroAtivo
}
