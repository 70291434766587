import styled, { css } from 'styled-components'

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: #fbfbfb;
  padding: 26px 40px;

  border-bottom: solid ${({ theme }) => theme.colors.primary} 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.sm} {
      max-height: 190px;
    }
  `}

  .menu-header {
    position: relative;
    font-size: ${({ theme }) => theme.font.sizes.large};
    font-weight: ${({ theme }) => theme.font.semiBold};
    cursor: pointer;
  }

  .menu-header h2 {
    margin: 0;
    padding: 0;
  }

  .menu-bar {
    position: absolute;
    bottom: -0.8rem; /* Ajuste a distância abaixo do texto conforme necessário */
    left: 0;
    width: 0;
    height: 0.5rem;
    background-color: #55a546; /* Defina a cor desejada para a barrinha */
    transition: width 0.3s ease; /* Animação para a expansão da barrinha */
  }

  .menu-header:hover .menu-bar {
    width: 100%; /* A barrinha ocupa a largura do texto ao passar o mouse */
  }
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1140px;

  height: 100%;

  color: ${({ theme }) => theme.colors.label};

  ${({ theme }) => css`
    ${theme.breakpoints.lg} {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  `}
`

export const itemsItemDropdownUserLoginButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2.2rem 2rem;

  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 8px;

  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dangerHover};
    .title-user-dropdown,
    > span > svg {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  > span > svg {
    font-size: 2rem;
    margin-right: ${({ theme }) => theme.spacings.xxsmall};
    color: ${({ theme }) => theme.colors.white};
  }

  .title-user-dropdown {
    flex: 1;

    font-size: ${({ theme }) => theme.font.sizes.small};
    line-height: ${({ theme }) => theme.font.sizes.medium};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const ColumnLeftHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex: 1;
  height: 100%;

  gap: 32px;

  ${({ theme }) => css`
    ${theme.breakpoints.md} {
      width: 100%;
    }
  `}
`
export const ColumnMiddleHeader = styled.div`
  color: ${({ theme }) => theme.colors.label};
  font-family: ${({ theme }) => theme.font.secondary};
  line-height: ${({ theme }) => theme.spacings.small};

  > h1 {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
    font-weight: ${({ theme }) => theme.font.extraBold};
  }
`

export const ColumnRightHeader = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-header-notification {
    position: relative;
  }

  > .content-welcome-image-user {
    display: flex;
    align-items: center;
  }

  > .content-welcome-image-user .content-text {
    display: flex;
    flex-direction: column;
    margin-right: ${({ theme }) => theme.spacings.xsmall};

    text-align: end;

    > .title-username {
      font-size: ${({ theme }) => theme.font.sizes.medium};
      margin-bottom: 6px;
      font-weight: 800;
      line-height: ${({ theme }) => theme.font.sizes.medium};
    }

    > .sub-title-usertype {
      font-size: ${({ theme }) => theme.font.sizes.medium};
      font-weight: 700;
      line-height: ${({ theme }) => theme.font.sizes.large};

      width: 100%;
      max-width: 18.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.lg} {
      width: 100%;
      margin-bottom: ${theme.spacings.xsmall};

      justify-content: space-between;
      margin-top: 5px;
    }

    ${theme.breakpoints.md} {
      align-items: flex-end;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacings.xsmall};
      margin-bottom: ${theme.spacings.xsmall};

      > .content-box {
        margin-right: 0px;
      }
    }
  `}
`

export const ImageUser = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.primary200};
  width: 35px;
  height: 35px;

  border-radius: 50px;
  border: ${({ theme }) => `2px ${theme.colors.primary} solid`};

  position: relative;
  object-fit: cover;
  object-position: center center;

  resize: none;
  overflow: hidden;

  > span {
    font-size: ${({ theme }) => theme.font.sizes.xlarge};
  }

  ${({ theme }) => css`
    ${theme.breakpoints.sm} {
      width: 40px;
      height: 40px;

      border-radius: 40px;
    }
  `}
`
