'use client'
import { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'

import { ButtonUI } from '@/components/UI/ButtonUI'

import { getInfoUser } from '@/services/UserService'

import LogoSecitece from '../../../../public/png/logo-alta-resolucao.png'

import {
  KEY_CACHE_PROFILE_USER,
  TypeRolesPermisson,
  profilesLabels
} from '@/constants/user-profiles-enums'

import * as S from './styles'
import { useRouter } from 'next/navigation'

type TUserData = {
  nameUser: string
  shortNameUser: string
  perfil?: TypeRolesPermisson
  image: string | null
}

export function PublicHeader() {
  const router = useRouter()
  const userConnected = Cookies.get(KEY_CACHE_PROFILE_USER)

  const [userData, setUserData] = useState<TUserData>({
    nameUser: '',
    shortNameUser: '',
    perfil: undefined,
    image: null
  })

  useEffect(() => {
    const user = getInfoUser()

    if (!user || typeof user.usuario.nome !== 'string') {
      setUserData({
        nameUser: '',
        shortNameUser: '',
        perfil: undefined,
        image: null
      })
      return
    }

    setUserData({
      nameUser: user.usuario.nome.split(' ')[0],
      shortNameUser: user.usuario.nome[0],
      perfil: (user.usuario.perfilList[0] as TypeRolesPermisson) || undefined,
      image: user.image
    })
  }, [])

  return (
    <S.ContainerHeader>
      <S.ContentHeader>
        <S.ColumnLeftHeader>
          <Link href="/">
            <Image
              src={LogoSecitece}
              alt="Logo"
              width={217}
              height={78}
              priority
            />
          </Link>

          <Link href="/" passHref>
            <div
              className={`menu-header ${router.pathname === '/' ? 'active' : ''}`}
            >
              <h2>INÍCIO</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
          <Link href="/dashboards/admin-geral" passHref>
            <div
              className={`menu-header ${router.pathname === '/dashboards/admin-geral' ? 'active' : ''}`}
            >
              <h2>INDICADORES</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
          <Link href="/" passHref>
            <div
              className={`menu-header ${router.pathname === '/vitrine' ? 'active' : ''}`}
            >
              <h2>VITRINE</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
          <Link href="/" passHref>
            <div
              className={`menu-header ${router.pathname === '/vitrine' ? 'active' : ''}`}
            >
              <h2>PROGRAMAS</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
          <Link href="/" passHref>
            <div
              className={`menu-header ${router.pathname === '/vitrine' ? 'active' : ''}`}
            >
              <h2>EVENTOS</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
          <Link href="/" passHref>
            <div
              className={`menu-header ${router.pathname === '/vitrine' ? 'active' : ''}`}
            >
              <h2>CURSOS</h2>
              <div className="menu-bar"></div>
            </div>
          </Link>
        </S.ColumnLeftHeader>

        <S.ColumnRightHeader>
          {userData.nameUser && !!userConnected ? (
            <div className="content-welcome-image-user">
              <div className="content-text">
                <span className="title-username">{userData.nameUser}</span>
                <Tooltip
                  title={userData.nameUser}
                  placement="bottom"
                  mouseLeaveDelay={0}
                >
                  <span className="sub-title-usertype">
                    {(userData.perfil && profilesLabels[userData.perfil]) ||
                      'Perfil não disponível'}
                  </span>
                </Tooltip>
              </div>

              <S.ImageUser>
                {userData?.image ? (
                  <Image
                    src={userData?.image}
                    fill
                    style={{ objectFit: 'cover' }}
                    sizes="(max-width: 50px) 100vw, 50px"
                    alt={`Retrato do usuário: ${userData.nameUser}`}
                  />
                ) : (
                  <span>{userData.shortNameUser || ''}</span>
                )}
              </S.ImageUser>
            </div>
          ) : (
            <Link href={`/entrar`}>
              <ButtonUI size="medium">Entrar</ButtonUI>
            </Link>
          )}
        </S.ColumnRightHeader>
      </S.ContentHeader>
    </S.ContainerHeader>
  )
}
