import {
  PieChartOutlined,
  RocketOutlined,
  EditOutlined
} from '@ant-design/icons'

import { PublicHeader } from '@/components/Headers/PublicHeader'

import * as S from './styles'

export function LandingPage() {
  return (
    <S.ContainerLandingPage>
      <PublicHeader />

      <S.SectionInfo>
        <div className="content">
          <S.BgLandingPage />
          <div>
            <span className="title">
              Plataforma de Indicadores <br />
              da SECITECE
            </span>
            <span className="description">
              Ferramenta para análise de dados e monitoramento de indicadores
              estratégicos dos Programas da Secretaria da Ciência, Tecnologia e
              Educação Superior.
            </span>
          </div>
        </div>
      </S.SectionInfo>

      <div className="content">
        <S.SectionListServices>
          <S.Card>
            <PieChartOutlined />
            <S.TitleCard>
              <span>Indicadores</span>
            </S.TitleCard>
            <span>
              Painéis visuais de monitoramento de dados com informações
              detalhadas sobre os indicadores da SECITECE.
            </span>
          </S.Card>

          <S.Card>
            <RocketOutlined />
            <S.TitleCard>
              <span>Vitrine</span>
            </S.TitleCard>
            <span>
              Conheça as iniciativas inovadoras promovidas pela SECITECE, que
              incluem cursos, startups aceleradas e editais com chamadas
              abertas.
            </span>
          </S.Card>

          <S.Card>
            <EditOutlined />
            <S.TitleCard>
              <span>Inscrições</span>
            </S.TitleCard>
            <span>
              Participe dos eventos, cursos e programas promovidos pela
              SECITECE, voltados ao desenvolvimento da ciência, tecnologia e
              inovação
            </span>
          </S.Card>
        </S.SectionListServices>
      </div>
    </S.ContainerLandingPage>
  )
}
