import styled from 'styled-components'

import BgImgfrom from '@/../public/images/img-bg.jpeg'

export const ContainerLandingPage = styled.div`
  > div.content {
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SectionInfo = styled.section`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #26a737e0;

  min-height: 60vh;

  > div.content {
    width: 100%;
    max-width: 1140px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;

      .title {
        font-size: 4.8rem;
        font-family: ${({ theme }) => theme.font.secondary};
        font-weight: ${({ theme }) => theme.font.normal};
        color: ${({ theme }) => theme.colors.white};
        line-height: 6rem;
      }

      .description {
        font-size: 2.4rem;
        line-height: 3rem;
        font-family: ${({ theme }) => theme.font.secondary};
        font-weight: ${({ theme }) => theme.font.normal};
        color: #e9ebf8;
      }
    }
  }
`

export const BgLandingPage = styled.div`
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;

  background-image: url(${BgImgfrom.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* max-width: 1140px;
  left: 50%;
  transform: translateX(-50%); */
  height: 100%;
`

export const SectionListServices = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 114px 0px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
`

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 7px 12px 43px 0px #00000024;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 14px 40px 30px;
  border-radius: 20px;

  & > span:first-child {
    font-size: 6rem;
    color: #407a9d;
  }

  > span {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    color: #052e2a;
  }
`

export const TitleCard = styled.div`
  font-size: 2.4rem;
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.font.bold};
  color: #052e2a;

  margin: 20px 0px;
  padding-bottom: 4px;
  border-bottom: 2px solid #26a737;
`
