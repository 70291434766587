import axios from 'axios'

import Cookies from 'js-cookie'

import { KEY_CACHE_TOKEN } from '@/constants/user-profiles-enums'

const apiPlataformaIndicadores = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 120000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

apiPlataformaIndicadores.interceptors.request.use((config) => {
  const token = Cookies.get(KEY_CACHE_TOKEN)

  if (token) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`
  }
  return config
})

export { apiPlataformaIndicadores }
